import { jsxs, Fragment } from 'react/jsx-runtime';
import * as React from 'react';
import { useTranslations } from 'next-intl';
import { classNames } from '../utils/classNames';

function Label(props) {
  const common = useTranslations("Common");
  const optionalText = common("optionalField");
  const elementType = props.element ?? "label";
  const element = React.createElement(
    elementType,
    {
      ...props.labelProps,
      className: classNames(
        "mb-1 dark:text-white",
        props.hiddenLabel && "sr-only",
        props.labelClassnames
      )
    },
    /* @__PURE__ */ jsxs(Fragment, { children: [
      props.label,
      " ",
      props.isOptional ? /* @__PURE__ */ jsxs("span", { className: "text-sm italic", children: [
        "(",
        optionalText,
        ")"
      ] }) : null
    ] })
  );
  return element;
}

export { Label };
